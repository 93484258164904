<script>
/**
 * QuickBooks Account Info
 * @version 1.0.0
 * @since
 */

import quickBooksGuard from '@/views/platform/venue/quick-books/settings/quickBooksGuard';
import {
  venueGetInfo,
  venueGetQuickBooksAccounts,
  venueGetQuickBooksTrackingCategories,
  venueGetQuickBooksTaxesSetup,
  venueQuickBooksSetupAccount,
} from '@/api/endpoints/quick-books';
import EzButton from '@/components/ui/Button/EzButton';
import flash from '@/components/ui/FlashMessage';
import { mapActions } from 'vuex';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  mixins: [quickBooksGuard],
  components: {
    EzButton,
    EzLoader,
    VSelectSearch,
    EzDropdown,
  },
  props: {
    venueId: {
      type: Number,
    },
  },
  data() {
    return {
      accounts: [],
      trackingCategories: [],
      departments: [],
      taxes: [],
      selectedAccount: '',
      selectedInventoryAccount: '',
      buttonDisabled: true,
      selectedTax: '',
      selectedDeliveryFeeAccount: '',
      selectedExportModeId: '',
      selectedAmountAdjustmentAccount: '',
      selectedTaxAdjustmentAccount: '',
      selectedZeroTaxAccount: '',
      selectedOrderDiscountAccount: '',
      selectedDepartment: '',
      loading: false,
      formKey: 'accountInfoQuickBooks',
      orderExportModes: [
        { id: 'manual', name: 'Manual' },
        { id: 'product_groups', name: 'Product Group Settings Required' },
        { id: 'auto', name: 'Automatic' },
      ],
    };
  },
  methods: {
    ...mapActions('entities/users', ['initVenue']),
    changeSelectedAccount(item) {
      this.selectedAccount = item.externalId;
      this.buttonDisabled = false;
    },
    changeSelectedInventoryAccount(item) {
      this.selectedInventoryAccount = item.externalId;
      this.buttonDisabled = false;
    },
    changeTax(item) {
      this.buttonDisabled = false;
      this.selectedTax = item.externalId;
    },
    changeDeliveryFee(item) {
      this.buttonDisabled = false;
      this.selectedDeliveryFeeAccount = item.externalId;
    },
    changeTaxAdjustment(item) {
      this.buttonDisabled = false;
      this.selectedTaxAdjustmentAccount = item.externalId;
    },
    changeZeroTax(item) {
      this.buttonDisabled = false;
      this.selectedZeroTaxAccount = item.externalId;
    },
    changeExportMode(item) {
      this.buttonDisabled = false;
      this.selectedExportModeId = item.id;
    },
    changeAmountAdjustment(item) {
      this.buttonDisabled = false;
      this.selectedAmountAdjustmentAccount = item.externalId;
    },
    changeOrderDiscount(item) {
      this.buttonDisabled = false;
      this.selectedOrderDiscountAccount = item.externalId;
    },
    changeDepartment(item) {
      this.buttonDisabled = false;
      this.selectedDepartment = item.externalId;
    },
    async saveChanges() {
      const account = {
        account: this.selectedAccount,
        venueInventoryAccount: this.selectedInventoryAccount,
        tax: this.selectedTax,
        deliveryFeeAccount: this.selectedDeliveryFeeAccount,
        accountingInvoicePush: this.selectedExportModeId,
        orderDiscountAccount: this.selectedOrderDiscountAccount,
        taxAdjustmentAccount: this.selectedTaxAdjustmentAccount,
        zeroTax: this.selectedZeroTaxAccount,
        amountAdjustmentAccount: this.selectedAmountAdjustmentAccount,
        department: this.selectedDepartment,
      };
      try {
        await venueQuickBooksSetupAccount(this.venueId, account, 'accountInfoQuickBooks');
        this.buttonDisabled = true;
        flash.success({
          title: this.$t('quickBooks.integrations.flashMessage.accountSuccess.title'),
        });
      } catch (e) {
        flash.error({
          title: this.$t('quickBooks.integrations.flashMessage.accountSuccess.error.title'),
        });
      }
    },
  },
  async created() {
    this.loading = true;
    try {
      this.initVenue();

      const {
        data: { data: accounts },
      } = await venueGetQuickBooksAccounts(this.venueId);
      this.accounts = accounts;
      const {
        data: { data: taxes },
      } = await venueGetQuickBooksTaxesSetup(this.venueId);
      this.taxes = taxes;
      const {
        data: { data: trackingCategories },
      } = await venueGetQuickBooksTrackingCategories(this.venueId);
      this.trackingCategories = trackingCategories;
      this.departments = trackingCategories.filter(item => item.key === 'department')[0].options;

      const {
        data: {
          data: { config },
        },
      } = await venueGetInfo(this.venueId);
      if (config) {
        this.selectedAccount = config.account;
        this.selectedInventoryAccount = config.venueInventoryAccount;
        this.selectedTax = config.tax;
        this.selectedDeliveryFeeAccount = config.deliveryFeeAccount;
        this.selectedExportModeId = config.accountingInvoicePush;
        this.selectedOrderDiscountAccount = config.orderDiscountAccount;
        this.selectedAmountAdjustmentAccount = config.amountAdjustmentAccount;
        this.selectedTaxAdjustmentAccount = config.taxAdjustmentAccount;
        this.selectedZeroTaxAccount = config.zeroTax;
        this.selectedDepartment = config.department;
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<template>
  <div class="container--small">
    <v-select-search
      class="account-dropdown width-100"
      align-left
      is-full-width
      :formKey="formKey"
      name="account"
      label="Account"
      :data="accounts"
      value-property="externalId"
      placeholder="Select Account"
      searchPlaceholder="Search Account"
      :selected="selectedAccount"
      :has-clear="false"
      @selected="changeSelectedAccount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="taxRates"
      label="Tax Rate"
      :data="taxes"
      value-property="externalId"
      placeholder="Select Tax Rate"
      searchPlaceholder="Search Tax Rate"
      :selected="selectedTax"
      :has-clear="false"
      @selected="changeTax"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="deliveryFeeAccountCode"
      label="Delivery Fee Account"
      :data="accounts"
      value-property="externalId"
      placeholder="Select Delivery Fee Account"
      searchPlaceholder="Search Delivery Fee Account"
      :selected="selectedDeliveryFeeAccount"
      :has-clear="false"
      @selected="changeDeliveryFee"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="taxAdjustmentAccountCode"
      label="Tax Adjustment Account"
      :data="accounts"
      value-property="externalId"
      placeholder="Select Tax Adjustment Account"
      searchPlaceholder="Search Delivery Fee Account"
      :selected="selectedTaxAdjustmentAccount"
      :has-clear="false"
      @selected="changeTaxAdjustment"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="amountAdjustmentAccountCode"
      label="Price Adjustment Account"
      :data="accounts"
      value-property="externalId"
      placeholder="Select Tax Adjustment Account"
      searchPlaceholder="Search Delivery Fee Account"
      :selected="selectedAmountAdjustmentAccount"
      :has-clear="false"
      @selected="changeAmountAdjustment"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="orderDiscountAccount"
      label="Order Discount Account"
      :data="accounts"
      value-property="externalId"
      placeholder="Select Order Discount Account"
      searchPlaceholder="Search Order Discount Account"
      :selected="selectedOrderDiscountAccount"
      :has-clear="false"
      @selected="changeOrderDiscount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="account-dropdown width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="account"
      label="Inventory Account"
      :data="accounts"
      value-property="externalId"
      placeholder="Select Inventory Account"
      searchPlaceholder="Search Inventory Account"
      :selected="selectedInventoryAccount"
      :has-clear="false"
      @selected="changeSelectedInventoryAccount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="department"
      label="Location"
      :data="departments"
      value-property="externalId"
      placeholder="Select Location"
      searchPlaceholder="Search Location"
      :selected="selectedDepartment"
      :has-clear="false"
      @selected="changeDepartment"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="zeroTax"
      label="Tax Rate for Non-Taxable Items (0%)"
      :data="taxes"
      value-property="externalId"
      placeholder="Select Tax Rate for Non-Taxable Items (0%)"
      searchPlaceholder="Search Location"
      :selected="selectedZeroTaxAccount"
      :has-clear="false"
      @selected="changeZeroTax"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <ez-dropdown
      name="accountingInvoicePush"
      label="Order Export Mode"
      placeholder="Select Order Export Mode"
      :formKey="formKey"
      class="mt-12"
      :data="orderExportModes"
      :selected="selectedExportModeId"
      @change="changeExportMode"
      is-full-width
    />
    <ez-button @click="saveChanges" :disabled="buttonDisabled" class="mt-24">
      Save Changes
    </ez-button>
    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
:deep() .select-search__trigger {
  span {
    color: $color-gray-6C;
  }
  svg {
    color: $color-gray-6C;
  }
  span.select-search__value {
    color: $color-gray-25;
  }
}

:deep() .select-search__list li.select-search__item {
  padding: 0.625rem 1rem;
}
</style>
