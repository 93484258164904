<script>
/**
 * QuickBooks Settings Page
 * @version 1.0.0
 * @since
 */
import Page from '@/views/admin/SinglePage.vue';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import { mapActions, mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { venueDisconnectQuickBooks } from '@/api/endpoints/quick-books';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
    Page,
    VStatusBadge,
    EzConfirmationModal,
  },
  computed: {
    ...mapGetters('entities/users', [
      'getVenues',
      'isVenueQuickBooksConnected',
      'isVenueQuickBooksTokenValid',
      'isVenueQuickBooksReAuthorizationNeeded',
    ]),
    venueId() {
      return this.$route.params.id;
    },
    isQuickBooksConnected() {
      return this.isVenueQuickBooksConnected(this.venueId);
    },
    isQuickBooksTokenValid() {
      return this.isVenueQuickBooksTokenValid(this.venueId);
    },
    canReauthorize() {
      return this.isVenueQuickBooksReAuthorizationNeeded(this.venueId);
    },
    organizationName() {
      const venue = this.getVenues.find(v => v.id === +this.venueId);
      return venue?.quickBooks?.name || 'QuickBooks';
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initVenue']),
    ...mapActions('quickBooks', ['quickBooksAuthorize']),
    reauthorizeQuickBooks() {
      this.quickBooksAuthorize({
        route: 'venue-quick-books-setup',
        state: this.venueId,
      });
    },
    async confirmQuickBooksDisconnect() {
      try {
        await venueDisconnectQuickBooks(this.venueId);
        this.$router.push({
          name: 'venue-settings-integrations',
          params: {
            flash: {
              title: 'You have successfully disconnected from QuickBooks.',
            },
          },
        });
      } finally {
        this.$refs.quickBooksDisconnectModal.close();
      }
    },
  },
  created() {
    this.initVenue();
  },
};
</script>
<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'venue-settings-integrations', params: { id: venueId } }">
        <font-awesome-icon icon="arrow-left" />
        Back to {{ $t('global.venue') }} Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center title">
        <img src="@/assets/logo-qb.png" width="32" height="32" alt="QuickBooks Logo" />
        <h2 class="m-0 ml-8 xero-title">{{ organizationName }}</h2>
        <v-status-badge type="green" v-if="isQuickBooksConnected">Connected</v-status-badge>
        <v-status-badge type="gray" v-else>Not Connected</v-status-badge>
      </div>
    </template>
    <template #actions>
      <div class="u-flex-center header-actions">
        <ez-button v-if="canReauthorize" class="mr-12" @click="reauthorizeQuickBooks">
          <font-awesome-icon icon="sync-alt" />
          <span>Reauthorize</span>
        </ez-button>
        <ez-button-dropdown v-if="isQuickBooksConnected" buttonType="secondary">
          <template #icon>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <template #dropdown>
            <ez-button v-open="'quickBooksDisconnectModal'" type="red-link">
              {{ $t('global.actions.disconnect') }}
            </ez-button>
          </template>
        </ez-button-dropdown>
      </div>
    </template>
    <template #navigation>
      <ul>
        <li>
          <component
            class="navigation-link"
            :is="isQuickBooksConnected ? 'router-link' : 'span'"
            :to="isQuickBooksConnected ? { name: 'venue-quick-books-account' } : null"
          >
            Account Info
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isQuickBooksConnected ? 'router-link' : 'span'"
            :to="isQuickBooksConnected ? { name: 'venue-quick-books-distributors' } : null"
          >
            {{ $t('global.distributors') }}
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isQuickBooksConnected ? 'router-link' : 'span'"
            :to="isQuickBooksConnected ? { name: 'venue-quick-books-products' } : null"
          >
            {{ $t('global.products') }}
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isQuickBooksConnected ? 'router-link' : 'span'"
            :to="isQuickBooksConnected ? { name: 'venue-quick-books-product-groups' } : null"
          >
            {{ $t('global.productGroups') }}
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isQuickBooksConnected ? 'router-link' : 'span'"
            :to="isQuickBooksConnected ? { name: 'venue-quick-books-tax' } : null"
          >
            Tax Rates
          </component>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="quickBooksDisconnectModal" type="red">
      <template #title>{{ $t('quickBooks.integrations.disconnectModalTitle') }}</template>
      <template #content>
        <div
          class="xero-description"
          v-html="$t('quickBooks.integrations.disconnectModalContent', { platform: platformName })"
        />
      </template>
      <template #footer>
        <ez-button v-close="'quickBooksDisconnectModal'" type="link">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button @click="confirmQuickBooksDisconnect" type="red">
          {{ $t('global.actions.disconnect') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </page>
</template>
<style lang="scss" scoped>
.xero-title {
  @include font-size(24px);
  font-weight: 500;
}
.title {
  img {
    border: 1px solid #e1e5ed;
    border-radius: 50%;
  }
}
.header-actions__action {
  height: 43px;
}
</style>
