import { mapActions, mapGetters } from 'vuex';

/**
 * Helper mixin that checks if the user is connected with QuickBooks.'
 * This guard is made as a mixin because we need to fetch data before checking.
 * Therefore, this can't be on the router level.
 */
export default {
  computed: {
    ...mapGetters('entities/users', ['isVenueQuickBooksConnected']),
  },
  methods: {
    ...mapActions('entities/users', ['initVenueLoggedUser']),
  },
  async created() {
    await this.initVenueLoggedUser();
    if (!this.isVenueQuickBooksConnected) {
      this.$router.replace({
        name: 'venue-quick-books-not-connected',
        params: {
          flash: {
            title: 'You are not connected to QuickBooks.',
            type: 'fail',
          },
        },
      });
    }
  },
};
