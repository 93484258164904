var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"products__header mb-24"},[_c('span',{staticClass:"products__info-text"},[_vm._v(" "+_vm._s(_vm.productGroups.length ? _vm.$t('quickBooks.settings.productGroups.infoText') : '')+" ")])]),_c('ez-filter-list',{staticClass:"mb-24",attrs:{"filters":_vm.filters},on:{"filterUpdated":_vm.updateFilters,"resetFilter":_vm.resetFilters}},[_c('ez-input',{staticClass:"search",attrs:{"formKey":"filters","name":"search","placeholder":`Search for a ${_vm.$t('global.productGroup')}`},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"search"}})]},proxy:true}])})],1),_c('div',[(_vm.productGroups.length)?_c('ez-table',{attrs:{"data":_vm.productGroups,"columns":_vm.allColumns,"headers":{
        name: _vm.nameCheckbox,
        sales: () => 'Account',
        inventory: () => 'Inventory Account',
        ..._vm.columnHeaders,
      },"disable-hover":true},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('div',{staticClass:"u-flex-row u-flex-center"},[_c('ez-checkbox',{key:row.id,staticClass:"cursor-pointer mr-8",attrs:{"checked":_vm.isProductGroupSelected(row)},on:{"change":function($event){return _vm.onCheckboxChange(row, $event)}}}),_c('span',{staticClass:"product-info"},[_vm._v(_vm._s(row.name))])],1)]}},_vm._l((_vm.dynamicColumns),function(cl){return {key:`cell-${cl}`,fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.getTrackingValue(row, cl))+" ")]}}}),{key:"cell-sales",fn:function({ row }){return [(row.integrationConfig && row.integrationConfig.account)?_c('v-data-with-info',{attrs:{"show-underline":row.integrationConfig.account.default,"info":row.integrationConfig.account.default
              ? _vm.$t('quickBooks.settings.table.account.defaultInfo')
              : ''}},[_vm._v(" "+_vm._s(row.integrationConfig.account.name)+" ")]):_c('span',[_vm._v("-")])]}},{key:"cell-inventory",fn:function({ row }){return [(row.integrationConfig && row.integrationConfig.venueInventoryAccount)?_c('v-data-with-info',{attrs:{"show-underline":row.integrationConfig.venueInventoryAccount.default,"info":row.integrationConfig.venueInventoryAccount.default
              ? _vm.$t('quickBooks.settings.table.account.defaultInfo')
              : ''}},[_vm._v(" "+_vm._s(row.integrationConfig.venueInventoryAccount.name)+" ")]):_c('span',[_vm._v("-")])]}},{key:"cell-taxRate",fn:function({ row: { integrationConfig } }){return [(integrationConfig && integrationConfig.tax)?_c('v-data-with-info',{attrs:{"show-underline":integrationConfig.tax.default,"info":integrationConfig.tax.default ? _vm.$t('xero.settings.table.account.defaultInfo') : ''}},[_vm._v(" "+_vm._s(integrationConfig.tax.name)+" ")]):_c('span',[_vm._v("-")])]}}],null,true)}):_vm._e(),(_vm.isLoadingMore)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e(),(!_vm.isLoadingMore && _vm.meta.nextId)?_c('ez-load-more',{on:{"loadMore":_vm.onLoadMore}}):_vm._e()],1),(!_vm.productGroups.length)?[_c('empty-state',{staticClass:"empty-state-center",scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/no-product-empty-state.svg"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('quickBooks.settings.productGroups.emptyState.title')))]},proxy:true},{key:"info",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('quickBooks.settings.productGroups.emptyState.info'))+" ")]},proxy:true}],null,false,3645388009)})]:_vm._e(),_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v("Loading...")]),(_vm.accountsData && _vm.accountsData.accounts)?_c('update-modal',{ref:"updateModal",attrs:{"entity":_vm.entity,"categories":_vm.trackingCategories,"acc-data":_vm.accountsData,"taxes":_vm.taxes},on:{"update":_vm.bulkActionsForProductGroups}}):_vm._e(),(_vm.bulkActionProductGroupsCount)?_c('footer-for-update',{attrs:{"entity":_vm.entity,"items-count":_vm.bulkActionProductGroupsCount},on:{"footerAction":_vm.openUpdateModal}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }