<script>
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import { camalize } from '@/util/utils';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'UpdateModal',
  components: {
    EzConfirmationModal,
    EzButton,
    EzCheckbox,
    VSelectSearch,
  },
  props: {
    entity: {
      type: String,
      required: false,
      default: 'Products',
    },
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
    accData: {
      type: Object,
      required: true,
      default: () => {},
    },
    taxes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      formKey: 'updateModalQuickBooks',
      categoriesCopy: [],
      isAccChecked: false,
      isInventoryAccChecked: false,
      isTaxRateChecked: false,
      selectedCategories: {},
      config: {},
      disabled: true,
      account: '',
      inventoryAccount: '',
    };
  },
  computed: {
    mappedCategories() {
      return this.categoriesCopy;
    },
    accountDisabled() {
      return !this.isAccChecked || (this.isAccChecked && this.account === '');
    },
    inventoryAccountDisabled() {
      return (
        !this.isInventoryAccChecked || (this.isInventoryAccChecked && this.inventoryAccount === '')
      );
    },
    taxRateDisabled() {
      return !this.isTaxRateChecked || (this.isTaxRateChecked && this.taxRate === '');
    },
    restDisabled() {
      return this.disabled;
    },
    isDisabled() {
      return (
        this.accountDisabled &&
        this.restDisabled &&
        this.taxRateDisabled &&
        this.inventoryAccountDisabled
      );
    },
    accounts() {
      return this.accData?.accounts;
    },
  },
  methods: {
    onClose() {
      this.mappedCategories.forEach(cat => {
        const comp = this.$refs[cat.ref];
        comp[0].reset();
        cat.checked = false;
      });
      this.isAccChecked = false;
      this.isInventoryAccChecked = false;
      this.isTaxRateChecked = false;
      this.$refs.accDropdown.reset();
      this.$refs.inventoryAccDropdown.reset();
      this.account = '';
      this.inventoryAccount = '';
      this.disabled = true;
      this.config = {};
      this.selectedCategories = {};
    },
    open() {
      this.$refs.updateModal.open();
    },
    close() {
      this.$refs.updateModal.close();
    },
    onCheckboxChange(cat, ev) {
      const category = this.categoriesCopy.find(ct => ct.key === cat.key);
      category.checked = ev;
      if (!ev) {
        const comp = this.$refs[category.ref];
        comp[0].reset();
      }
      this.onDropdownChange(category, null);
    },
    onDropdownChange(cat, ev) {
      this.selectedCategories = {
        ...this.selectedCategories,
        [cat.key]: ev?.externalId || null,
      };

      const reducedObj = Object.keys(this.selectedCategories).reduce((newObj, key) => {
        const value = this.selectedCategories[key];
        if (value !== '') {
          newObj[key] = value;
        }
        return newObj;
      }, {});

      this.config = {
        ...this.config,
        tracking: {
          ...reducedObj,
        },
      };
      const equal =
        this.categoriesCopy.filter(ct => ct.checked)?.length ===
        Object.values(this.config.tracking)?.length;
      const checkBoxLength = this.categoriesCopy.filter(ct => ct.checked)?.length === 0;
      this.disabled = !equal || checkBoxLength;
    },
    toggleAcc(ev) {
      this.isAccChecked = ev;
      if (!ev) {
        this.$refs.accDropdown.reset();
        this.account = '';
        delete this.config.account;
      }
    },
    toggleInventoryAcc(ev) {
      this.isInventoryAccChecked = ev;
      if (!ev) {
        this.$refs.inventoryAccDropdown.reset();
        this.inventoryAccount = '';
        delete this.config.venueInventoryAccount;
      }
    },
    toggleTaxRate(ev) {
      this.isTaxRateChecked = ev;
      if (!ev) {
        this.$refs.taxRateDropdown.reset();
        this.tax = '';
        delete this.config.tax;
      }
    },
    accChanged(ev) {
      this.account = ev.externalId;
      this.config = {
        ...this.config,
        account: ev.externalId,
      };
    },
    inventoryAccChanged(ev) {
      this.inventoryAccount = ev.externalId;
      this.config = {
        ...this.config,
        venueInventoryAccount: ev.externalId,
      };
    },
    taxRateChanged(ev) {
      this.tax = ev.externalId;
      this.config = {
        ...this.config,
        tax: ev.externalId,
      };
    },
    update() {
      this.$emit('update', this.config);
    },
  },
  created() {
    this.categoriesCopy = this.categories.map(cat => ({
      ...cat,
      checked: false,
      ref: camalize(cat.name),
    }));
  },
  watch: {
    categories() {
      this.categoriesCopy = this.categories.map(cat => ({
        ...cat,
        checked: false,
        ref: camalize(cat.name),
      }));
    },
  },
};
</script>

<template>
  <ez-confirmation-modal @close="onClose" ref="updateModal" icon="question">
    <template #title>Update {{ entity }}?</template>
    <template #content>
      <p>Choose what information you would like to change for selected {{ entity }}.</p>
      <hr />
      <ez-checkbox
        label="Account"
        class="cursor-pointer mb-12"
        :checked="isAccChecked"
        @change="toggleAcc"
      />
      <v-select-search
        ref="accDropdown"
        :disable="!isAccChecked"
        class="mb-12 width-100"
        align-left
        is-full-width
        :formKey="formKey"
        name="account"
        :data="accounts"
        value-property="externalId"
        placeholder="Select Account"
        searchPlaceholder="Search Account"
        :has-clear="false"
        @selected="accChanged"
      >
        <template #result="{ result }">
          <span>
            {{ result.name }}
          </span>
        </template>
      </v-select-search>
      <ez-checkbox
        label="Inventory Account"
        class="cursor-pointer mb-12"
        :checked="isInventoryAccChecked"
        @change="toggleInventoryAcc"
      />
      <v-select-search
        ref="inventoryAccDropdown"
        class="account-dropdown width-100 mb-12"
        :disable="!isInventoryAccChecked"
        align-left
        is-full-width
        :formKey="formKey"
        name="account"
        :data="accounts"
        value-property="externalId"
        placeholder="Select Inventory Account"
        searchPlaceholder="Search Inventory Account"
        :has-clear="false"
        @selected="inventoryAccChanged"
      >
        <template #result="{ result }">
          <span>
            {{ result.name }}
          </span>
        </template>
      </v-select-search>
      <hr />
      <ez-checkbox
        label="Tax Rate"
        class="cursor-pointer mb-12"
        :checked="isTaxRateChecked"
        @change="toggleTaxRate"
      />
      <v-select-search
        ref="taxRateDropdown"
        :disable="!isTaxRateChecked"
        class="width-100 mb-12"
        align-left
        is-full-width
        name="account"
        :data="taxes"
        value-property="externalId"
        placeholder="Select Tax Rate"
        searchPlaceholder="Search Tax Rate"
        :has-clear="false"
        @selected="taxRateChanged"
      >
        <template #result="{ result }">
          <span>
            {{ result.name }}
          </span>
        </template>
      </v-select-search>
      <hr v-if="mappedCategories.length" />
      <p class="cat-label" v-if="mappedCategories.length">Tracking Categories</p>
      <div v-for="cat in mappedCategories" :key="cat.key">
        <ez-checkbox
          v-if="cat.options.length"
          :label="cat.name"
          class="cursor-pointer mb-12"
          :checked="cat.checked"
          @change="onCheckboxChange(cat, $event)"
        />
        <v-select-search
          v-if="cat.options.length"
          :ref="cat.ref"
          :disable="!cat.checked"
          class="width-100 mb-12"
          align-left
          is-full-width
          name="account"
          :data="cat.options"
          value-property="externalId"
          :placeholder="`Select ${cat.name}`"
          :searchPlaceholder="`Search ${cat.name}`"
          :has-clear="false"
          @selected="onDropdownChange(cat, $event)"
        >
          <template #result="{ result }">
            <span>
              {{ result.name }}
            </span>
          </template>
        </v-select-search>
      </div>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button :disabled="isDisabled" @click="update">Update {{ entity }}</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.cat-label {
  @include font-size(14px, 22px);
  color: $color-gray-25;
}

:deep() .select-search__trigger {
  span {
    color: $color-gray-6C;
  }
  svg {
    color: $color-gray-6C;
  }
  span.select-search__value {
    color: $color-gray-25;
  }
}

:deep() .select-search--disabled .select-search__trigger {
  span,
  svg {
    color: $input-disabled-color;
  }
}

:deep() .select-search__list li.select-search__item {
  padding: 0.625rem 1rem;
}
</style>
