<script>
/**
 * QuickBooks Products
 * @version 1.0.0
 * @since
 */

import quickBooksGuard from '@/views/platform/venue/quick-books/settings/quickBooksGuard';
import {
  venueGetProducts,
  venueGetQuickBooksAccounts,
  venueUpdateQuickBooksProducts,
  venueGetQuickBooksTrackingCategories,
  venueGetQuickBooksTaxesSetup,
} from '@/api/endpoints/quick-books';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import EzTable from '@/components/ui/Table/EzTable';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import FooterForUpdate from '@/views/platform/venue/xero/settings/FooterForUpdate';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import UpdateModal from '@/views/platform/venue/quick-books/settings/UpdateModal';
import { debounce } from '@/util/utils';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  mixins: [quickBooksGuard],
  components: {
    UpdateModal,
    VDataWithInfo,
    EzLoadMore,
    EzSpinner,
    FooterForUpdate,
    EzEntityInfo,
    EzCheckbox,
    EmptyState,
    EzTable,
    EzLoader,
    EzFilterList,
    EzInput,
    EzDropdown,
  },
  props: {
    venueId: {
      type: Number,
    },
  },
  data() {
    return {
      syncedItems: [],
      meta: {},
      products: [],
      dynamicColumns: [],
      trackingCategories: [],
      accountsData: {},
      taxes: [],
      selected: false,
      listOfProductIds: [],
      columnHeaders: {},
      filters: {
        term: null,
        operative: true,
      },
      operativeFilterOptions: [
        {
          id: 1,
          name: 'Operative',
        },
        {
          id: 2,
          name: 'All',
        },
      ],
      operativeId: 1,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    ...mapState('quickBooks', ['bulkActionProducts']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.VENUE_GET_QUICK_BOOKS_PRODUCTS,
        LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_PRODUCTS,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_GET_MORE_PRODUCTS);
    },
    allColumns() {
      return ['name', ...this.dynamicColumns, 'sales', 'inventory', 'taxRate', 'price'];
    },
    entity() {
      return this.$t('global.products');
    },
    bulkActionProductsCount() {
      return this.selected
        ? this.meta.totalCount - this.listOfProductIds.length
        : this.listOfProductIds.length;
    },
    areAllSelected() {
      return this.bulkActionProductsCount === this.meta.totalCount;
    },
  },
  methods: {
    ...mapMutations('quickBooks', ['UPDATE_BULK_ACTION_PRODUCTS', 'CLEAR_BULK_ACTION_PRODUCTS']),
    openSyncModal() {
      this.$refs.productsSync.open();
    },
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center' }, [
        h(
          'ez-checkbox',
          {
            class: 'mr-8',
            props: {
              label: 'Product',
              indeterminate: this.bulkActionProductsCount > 0 && !this.areAllSelected,
              checked: this.areAllSelected,
            },
            on: {
              change: event => {
                this.selectDeselectAll(event);
              },
            },
          },
          [],
        ),
      ]);
    },
    async selectDeselectAll(selected) {
      this.selected = selected;
      this.listOfProductIds = [];
      const bulkActionProducts = {};
      this.products.forEach(prod => {
        bulkActionProducts[prod.id] = selected;
      });

      this.UPDATE_BULK_ACTION_PRODUCTS(bulkActionProducts);
    },
    onCheckboxChange(product, checked) {
      if (this.selected !== checked) this.listOfProductIds.push({ productId: product.id });
      else
        this.listOfProductIds = this.listOfProductIds.filter(item => item.productId !== product.id);
      this.UPDATE_BULK_ACTION_PRODUCTS({ [product.id]: checked });
    },
    async bulkActionsForProducts(config) {
      const items = Object.entries(this.bulkActionProducts)
        .filter(([, value]) => value === !this.selected)
        .map(productId => ({
          id: Number(productId[0]),
        }));
      await venueUpdateQuickBooksProducts({
        id: this.venueId,
        body: {
          items,
          config,
          state: this.selected
            ? SELECT_DESELECT_ALL.selectedAll
            : SELECT_DESELECT_ALL.deselectedAll,
        },
        term: this.filters.term,
      });

      const {
        data: { data, meta },
      } = await venueGetProducts({
        id: this.venueId,
        ...this.filters,
      });
      this.products = data;
      this.setMeta(meta);

      this.$refs.updateModal.close();
    },
    isProductSelected(product) {
      return this.bulkActionProducts[product.id];
    },
    async onLoadMore(params = {}) {
      const {
        data: { data, meta },
      } = await venueGetProducts({
        id: this.venueId,
        ...this.meta,
        loadingKey: LOADING_KEY.VENUE_GET_MORE_PRODUCTS,
        ...params,
        ...this.filters,
      });
      this.products = [...this.products, ...data];
      if (this.selected) {
        data.forEach(prod => {
          this.UPDATE_BULK_ACTION_PRODUCTS({
            [prod.id]: !(
              this.bulkActionProducts[prod.id] !== undefined &&
              this.bulkActionProducts[prod.id] === false
            ),
          });
        });
      }
      this.setMeta(meta);
    },
    openUpdateModal() {
      this.$refs.updateModal.open();
    },
    setMeta(meta) {
      this.meta = meta;
      this.dynamicColumns = meta.columns.map(column => column.key);
      meta.columns.forEach(item => {
        this.columnHeaders[item.key] = () => item.name;
      });
    },
    getTrackingValue(product, col) {
      return product?.integrationConfig?.tracking?.[col]?.name || '-';
    },
    async resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      this.CLEAR_BULK_ACTION_PRODUCTS();
      this.products = [];
      const {
        data: { data, meta },
      } = await venueGetProducts({ id: this.venueId });
      this.products = data;
      this.setMeta(meta);
      this.selectDeselectAll(false);
      this.operativeId = null;
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.CLEAR_BULK_ACTION_PRODUCTS();
      this.products = [];
      const {
        data: { data, meta },
      } = await venueGetProducts({ id: this.venueId, ...this.filters });
      this.products = data;
      this.setMeta(meta);
      this.selectDeselectAll(false);
    }, 300),
    setOperativeFilter(item) {
      this.operativeId = item.id;
      if (item.id === 1) this.filters.operative = true;
      else this.filters.operative = false;
    },
  },
  watch: {
    bulkActionProductsCount(val) {
      window.Intercom('update', {
        hide_default_launcher: !!val,
      });
    },
  },
  async created() {
    const {
      data: { data, meta },
    } = await venueGetProducts({ id: this.venueId, ...this.filters });
    this.products = data;
    this.setMeta(meta);

    const {
      data: { data: categories },
    } = await venueGetQuickBooksTrackingCategories(this.venueId, { withDefault: true });
    this.dynamicColumns.forEach(item => {
      this.trackingCategories.push(...categories.filter(category => category.key === item));
    });

    const {
      data: { data: accounts },
    } = await venueGetQuickBooksAccounts(this.venueId, { withDefault: true });
    this.accountsData = { accounts };

    const {
      data: { data: taxes },
    } = await venueGetQuickBooksTaxesSetup(this.venueId, { withDefault: true });
    this.taxes = taxes;
  },
};
</script>
<template>
  <div>
    <div class="products__header mb-24">
      <span class="products__info-text">
        {{ products.length ? $t('quickBooks.settings.products.infoTextVenue') : '' }}
      </span>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.product')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
      <ez-dropdown
        ref="select"
        class="operative-filter ml-12"
        label="Operative"
        placeholder="Filter Products"
        :data="operativeFilterOptions"
        name="operative"
        is-full-width
        @change="setOperativeFilter"
        :selected="operativeId"
      />
    </ez-filter-list>
    <div>
      <ez-table
        v-if="products.length"
        :data="products"
        :columns="allColumns"
        :column-props="{
          price: {
            class: 'price-cell',
          },
        }"
        :headers="{
          name: nameCheckbox,
          sales: () => 'Account',
          inventory: () => 'Inventory Account',
          ...columnHeaders,
        }"
        :disable-hover="true"
      >
        <template #cell-name="{ row }">
          <div class="u-flex-row u-flex-center">
            <ez-checkbox
              class="cursor-pointer mr-8"
              :key="row.id"
              :checked="isProductSelected(row)"
              @change="onCheckboxChange(row, $event)"
            />
            <ez-entity-info class="width-100" imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
              <div
                class="width-90 cursor-pointer"
                :title="row.name"
                @click="onCheckboxChange(row, !isProductSelected(row))"
              >
                <span class="product-info__name">
                  <span class="product-info">{{ row.name }}</span>
                </span>
                <span class="product-info-secondary">
                  {{ row.sku }}
                </span>
              </div>
            </ez-entity-info>
          </div>
        </template>

        <template v-for="cl in dynamicColumns" #[`cell-${cl}`]="{ row }">
          {{ getTrackingValue(row, cl) }}
        </template>

        <template #cell-sales="{ row }">
          <v-data-with-info
            v-if="row.integrationConfig && row.integrationConfig.account"
            :show-underline="row.integrationConfig.account.default"
            :info="
              row.integrationConfig.account.default
                ? $t('quickBooks.settings.table.account.defaultInfo')
                : ''
            "
          >
            {{ row.integrationConfig.account.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
        <template #cell-inventory="{ row }">
          <v-data-with-info
            v-if="row.integrationConfig && row.integrationConfig.venueInventoryAccount"
            :show-underline="row.integrationConfig.venueInventoryAccount.default"
            :info="
              row.integrationConfig.venueInventoryAccount.default
                ? $t('quickBooks.settings.table.account.defaultInfo')
                : ''
            "
          >
            {{ row.integrationConfig.venueInventoryAccount.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-taxRate="{ row: { integrationConfig } }">
          <v-data-with-info
            v-if="integrationConfig && integrationConfig.tax"
            :show-underline="integrationConfig.tax.default"
            :info="
              integrationConfig.tax.default ? $t('xero.settings.table.account.defaultInfo') : ''
            "
          >
            {{ integrationConfig.tax.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-price="{ row: { price } }">
          {{ price | price }}
        </template>
      </ez-table>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />
    </div>
    <template v-if="!products.length">
      <empty-state class="empty-state-center">
        <template #badge> <img src="@/assets/no-product-empty-state.svg" alt="" /></template>

        <template #title>{{ $t('quickBooks.settings.products.emptyState.title') }}</template>
      </empty-state>
    </template>

    <ez-loader :show="isLoading">Loading...</ez-loader>

    <update-modal
      v-if="accountsData && accountsData.accounts"
      ref="updateModal"
      :entity="entity"
      :categories="trackingCategories"
      :acc-data="accountsData"
      :taxes="taxes"
      @update="bulkActionsForProducts"
    />

    <footer-for-update
      v-if="bulkActionProductsCount"
      :entity="entity"
      :items-count="bulkActionProductsCount"
      @footerAction="openUpdateModal"
    />
  </div>
</template>
<style lang="scss" scoped>
.products__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.products__info-text {
  @include font-size(14px, 20px);

  width: 400px;
  font-weight: 500;
  color: $color-gray-6C;
}

.synced-items-list {
  &--header {
    margin-bottom: 25px;
    @extend %flex-space;

    span {
      @include font-size(14px);
      color: $color-gray-6C;
    }
  }

  :deep() .table {
    tbody tr td {
      &.price-cell {
        color: $color-gray-25;
      }
    }
  }
}

.product-info__name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-info__name-icon {
  @include size(20px);

  border-radius: 50%;
}

:deep() .ez-filter-list__items .search,
:deep() .operative-filter.input-group {
  width: 260px;
}
</style>
