<script>
/**
 * QuickBooks Not Connected Screen
 * @version 1.0.0
 * @since
 */

import EmptyState from '@/views/common/empty-state';
import EzButton from '@/components/ui/Button/EzButton';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    EmptyState,
    EzButton,
    EzConfirmationModal,
  },
  computed: {
    ...mapGetters('entities/users', ['isVenueQuickBooksConnected']),
    venueId() { return this.$route.params.id; },
    isQuickBooksConnected() { return this.isVenueQuickBooksConnected(this.venueId); },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('quickBooks', ['quickBooksAuthorize']),
    ...mapActions('entities/venues', ['fetchPlatformVenue']),
    ...mapActions('entities/users', ['initVenue']),
    confirmQuickBooksConnection() {
      this.quickBooksAuthorize({
        route: 'venue-quick-books-setup',
        state: this.venueId,
      });
    },
    redirectToSettings() {
      if (this.isQuickBooksConnected) {
        this.$router.replace({
          name: 'venue-quick-books-account',
          params: {
            flash: {
              title: 'You are already connected to QuickBooks.',
              type: 'success',
            },
          },
        });
      }
    },
  },
  async created() {
    await this.fetchPlatformVenue(this.venueId);
    await this.initVenue();
    await this.redirectToSettings();
  },
};
</script>
<template>
  <div class="not-connected">
    <empty-state>
      <template #badge>
        <img src="@/assets/quick-books-empty-state.svg" alt="QuickBooks" width="160" height="138">
      </template>
      <template #title>Connect your QuickBooks account</template>
      <template #info>Connect your QuickBooks account, set up your account codes,
        tax rates and vendors, and {{ platformName }} will start pushing your orders to QuickBooks.
      </template>

      <div class="mt-16">
        <ez-button type="link" key="connectXero" v-open="'quickBooksConnectModal'" aria-label="Connect to QuickBooks">
          <img src="@/assets/quick-books-btn-default.svg" alt="Connect to QuickBooks" role="presentation" />
        </ez-button>
      </div>
    </empty-state>

    <ez-confirmation-modal ref="quickBooksConnectModal" icon="question">
      <template #title>{{ $t('quickBooks.integrations.confirmationModalTitle') }}</template>
      <template #content>
        <div class="xero-description"
             v-html="$t('quickBooks.integrations.confirmationModalContent', { platform: platformName })"/>
      </template>
      <template #footer>
        <ez-button type="link" v-close="'quickBooksConnectModal'">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button class="ml-12" @click="confirmQuickBooksConnection">
          {{ $t('global.actions.confirm') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>
<style lang="scss" scoped>
.not-connected { padding-top: 80px; }
</style>
