<script>
/**
 * Index
 * @version 1.0.0
 * @since
 */

import { venueAuthorize } from '@/api/endpoints/quick-books';
import EzLoader from '@/components/ui/Loader/EzLoader';

export default {
  components: {
    EzLoader,
  },
  provide() {
    return {
      venueId: this.venueId,
    };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    venueId() { return this.$route.query.state; },
  },
  async created() {
    if (!this.$route.query) {
      this.$router.push({
        name: 'venue-quick-books-not-connected',
        params: {
          flash: {
            message: 'Something went wrong!',
            type: 'error',
          },
        },
      });
    } else {
      const params = window.location.search;
      try {
        this.isLoading = true;
        await venueAuthorize(this.venueId, params);
        await this.$router.push({
          name: 'venue-quick-books-account',
          params: {
            id: this.venueId,
            flash: {
              title: this.$t('quickBooks.integrations.flashMessage.success.title'),
              message: this.$t('quickBooks.integrations.flashMessage.success.message'),
              type: 'success',
            },
          },
        });
      } catch (e) {
        this.$router.push({
          name: 'venue-quick-books-not-connected',
          params: {
            flash: {
              message: 'Something went wrong!',
              type: 'error',
            },
          },
        });
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
<template>
  <ez-loader :show="isLoading">Loading...</ez-loader>
</template>
<style lang="scss" scoped></style>
